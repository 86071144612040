@tailwind components;

@layer components {
  .DrawerMenu {
    border-radius: 10px 0px 0px 10px;
    /* animation: 1s slidein; */
    width: 70%;
    position: fixed;
    inset: 0px;
    left: 30%;
    transition: slidein ease .6s;

    /* for chrome and safari*/
    -webkit-animation-duration: 1s;
    -webkit-animation-name: slidein;

    /*for firefox*/
    -moz-animation-duration: 1s;
    -moz-animation-name: slidein;

    /* for opera*/
    -o-animation-duration: 1s;
    -o-animation-name: slidein;

    /* Standard syntax*/
    animation-duration: 1s;
    animation-name: slidein;
  }

  .gradient-bg {
    background-image: linear-gradient(180deg, rgba(16, 184, 87, .86) 0, rgba(16, 184, 87, .86) 1%, rgba(23, 183, 223, .86) 100%);
  }

  @-webkit-keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  @-moz-keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  @-o-keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }
}