.card{
    @apply rounded w-full;
}

.card-shadow{
    @apply shadow-default;
}

.card-header{
    @apply  border-0;
}

.card-footer{
    @apply border-0;
}

.card-body{
}