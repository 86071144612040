@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-family: 'Roboto', sans-serif;
  --neutral-lighter-90: 23, 24, 26 ; /* 23, 24, 26 #17181a */
  --neutral-lighter-70: 14, 35, 48 ; /* 14, 35, 48 #0e2330*/
  --neutral-lighter-60: 102, 117, 128 ; /* 102, 117, 128 #667580 */
  --neutral-lighter-40: 143, 165, 179 ; /* 143, 165, 179 #8FA5B3*/
  --neutral-lighter-30: 184, 196, 204 ; /* 184, 196, 204 #B8C4CC */
  --neutral-lighter-20: 207, 220, 230 ; /* 207, 220, 230 #CFDCE6*/
  --neutral-lighter-10: 242, 250, 255 ; /* 242, 250, 255 #F2FAFF*/
  --neutral-lighter-0: 255, 255, 255 ; /* 255, 255, 255 #FFFFFF */

  --neutral-darker-70: 40, 46, 51 ; /* 40, 46, 51 #282E33 */

  --primary-brand: 72, 175, 240; /* 72, 175, 240 #48AFF0*/
  --primary-flex-blue-70: 0, 85, 137; /* 0, 85, 137 #005589 primary-FlexBlue-70 */
  --primary-flex-blue-60: 0, 126, 204; /* 0, 126, 204 #007ECC primary-FlexBlue-60 */
  --support-green: 136, 205, 67; /* 136, 205, 67 #88cd43 support-green */
  --support-blue: 81, 105, 242; /* 81, 105, 242 #5169F2 support-blue */
  --support-yellow: 247, 191, 22; /* 247, 191, 22 #F7BF16 support-yellow */
  --support-red: 255, 73, 53; /* 255, 73, 53 #FF4935 support-red */
}

@layer base {
  body{
    font-family: var(--font-family);
    height: 100vh;
  }
  #root{
    height:100%;
    overflow: hidden;
  }
}

