@layer components {
    .dropdown-items {
        @apply
        w-48 absolute mt-1 flex flex-col rounded border;
        
      }
      .dropdown-item{
          @apply
          pt-0 pb-0 cursor-pointer
          text-sm
            py-1
            px-4
            font-normal
            block
            w-full
            whitespace-nowrap
            bg-transparent
            border-theme-primary 
            hover:bg-theme-hover 
            text-theme-primary
        }
    
        .dropdown-item:not(first-child){
        @apply
        pt-2 pb-2
        }

        .dropdown-item:not(last-child){
        @apply
        pt-2 pb-2
        }

        .isVisible{
        @apply
        visible

        }

        .isHidden{
        @apply
        invisible
        }
        .drop-right{
          top: 0;
          left: 125px;
        }
        .drop-top{
          top: 0;
          left: 125px;
        }
}