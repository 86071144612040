@layer components {

    .custom-group-button .btn{
        @apply 
            rounded-none w-full flex-grow;
    }
    
    .custom-group-button .btn:not(:last-child) {
        @apply 
            border-r-0
            rounded-none;
    }
    .custom-group-button .btn:nth-child(1){
        @apply 
           rounded-r-none;
    } 
    .custom-group-button .btn:last-child {
        @apply 
            rounded-l-none;
    }

}