@layer components {
    .right-search-button .form-control{
        @apply 
            border-r-0 rounded-r-none;
    }
    .search-box .form-group{
        @apply
            mb-0 flex-grow;
    }
    .right-search-button .btn{
        @apply rounded-l-none;
    }
    .left-search-button .form-control{
        @apply 
            border-l-0 rounded-l-none;
    }
    .left-search-button .btn{
        @apply rounded-r-none;
    }
}